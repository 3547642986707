import React, { Component } from 'react'

interface IProps {
  value: string,
}

export default class IdCardEncrypt extends Component<IProps> {
  render() {
    const { value = '', } = this.props
    return <div dangerouslySetInnerHTML={{ __html: value }}/>
  }
}
