import React, { Component } from 'react'
import { Menu, Dropdown, Avatar, Modal } from 'antd'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Link from '../../components/display/link'
import './header.less'
import UI, { IUI } from "../../store/ui";
import { inject, observer } from "mobx-react";
import Auth, { IAuth } from "../../store/auth";
import EditForm from "../../components/form/editForm";
import itemMapPlus from "../../components/form/itemMapPlus";
import Config from '../../config/config'
import Svg from '../../components/display/svg'

const { successErrno, format: { errno } } = Config

interface IPorps {
  UI?: IUI
  Auth?: IAuth
}

@inject('UI', 'Auth') @observer
class Header extends Component<IPorps & RouteComponentProps> {
  state = { isShowPasswordReset: false }
  menuClick = async ({ key }: { key: string }) => {
    const { Auth: { logout } = Auth, UI: { clearMyMenu } = UI } = this.props
    if (key === 'logout') {
      const outData = await logout()
      if (outData[errno] === successErrno) {
        clearMyMenu()
      }
    } else if (key === 'resetPassword') {
      this.setState({ isShowPasswordReset: true })
    }
  }

  render() {
    const { UI: { myMenu } = UI, Auth: { user, passwordResetStatus, passwordReset } = Auth, location: { pathname } } = this.props
    const tmpArr = pathname.replace(/^\//, '').split('/')
    const top = `/${tmpArr[0]}`
    const { isShowPasswordReset } = this.state
    return (
      <div id="b-header">
        <Link href='/' className="logo">童绘管理后台</Link>
        {!(pathname === '/' || pathname === 'login' || pathname === 'reset') &&
        <div className="m-top-menu">
          <Menu
            theme="dark"
            selectedKeys={[top]}
            mode="horizontal"
          >
            {myMenu && myMenu.map((item: any) =>
              <Menu.Item key={item.path}>
                <Link href={item.path}><Svg src={item.icon}/>{item.name}</Link>
              </Menu.Item>
            )}
          </Menu>
        </div>
        }
        {user.id ?
          <div className="m-account">
            <Dropdown overlay={(
              <Menu theme="dark" onClick={this.menuClick}>
                {/*<Menu.Item key="info"><Link href="/setting">个人信息</Link></Menu.Item>*/}
                <Menu.Item key="resetPassword">修改密码</Menu.Item>
                <Menu.Item key="logout">退出</Menu.Item>
              </Menu>
            )}>
              <p className="avatar"><Avatar size="small" icon="user"/> {user.name}</p>
            </Dropdown>
          </div> : null
        }
        <Modal
          title="修改密码"
          width={360}
          maskClosable={false}
          visible={isShowPasswordReset}
          okButtonProps={{ loading: passwordResetStatus.loading, disabled: !passwordResetStatus.submit }}
          onOk={() => passwordReset()}
          onCancel={() => this.setState({ isShowPasswordReset: false })}
          okText="修改">
          <EditForm itemMap={itemMapPlus} Store={Auth} name="passwordReset"/>
        </Modal>
      </div>
    )
  }
}

export default withRouter(Header)
