interface IConfig {
  hosts: { [key: string]: string }
  successErrno: number,
  authErrno: number,
  validatedErrno: number,
  mobileWidth: number,
  format: {
    errno: string,
    errmsg: string,
    data: string,
    page: string,
    pageSize: string,
    currentPage: string,
    count: string,
    totalPages: string
  },
}

const config: IConfig = {
  hosts: { api: '' },
  successErrno: 0,
  authErrno: 401001,
  validatedErrno: 403001,
  mobileWidth: 768,
  format: {
    errno: 'errno',
    errmsg: 'errmsg',
    data: 'data',
    page: 'page',
    pageSize: 'pageSize',
    currentPage: 'currentPage',
    count: 'count',
    totalPages: 'totalPage'
  },
}


const ENV = process.env.REACT_APP_API_ENV
switch (ENV) {
  case 'local':
    config.hosts = {
      api: '//local-admin-api.kidhappy.cn',
    }
    break;
  case 'dev':
    config.hosts = {
      api: '//dev-admin-api.kidhappy.cn',
    }
    break;
  case 'test':
    config.hosts = {
      api: '//test-admin-api.kidhappy.cn',
    }
    break;
  case 'pre':
    config.hosts = {
      api: '//pre-admin-api.kidhappy.cn',
    }
    break;
  case 'prod':
    config.hosts = {
      api: '//admin-api.kidhappy.cn',
    }
    break;
  default:
    config.hosts = {
      api: '//dev-admin-api.kidhappy.cn',
    }
}
export default config
