import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { Spin } from 'antd'
import { IAuth } from "../../store/auth";

interface IProps {
  code?: number | string,
  msg?: string | object
  loading?: boolean
  Auth?: IAuth
}

@inject('Auth') @observer
export default class Content extends Component<IProps> {
  render() {
    const errArr = []
    const { code = 0, msg = '', loading = false, children = null, Auth } = this.props
    if (typeof msg === 'object') {
      Object.keys(msg).forEach((key) => {
        errArr.push(msg[key])
      })
    } else {
      errArr.push(msg)
    }
    if (!loading && code === 401001) {
      if (process.browser && Auth && Auth.setUser) {
        setTimeout(() => {
          Auth.setUser()
        }, 200)
      }
      return (<Redirect to="/login"/>)
    }
    return (
      <Spin spinning={loading} delay={400} tip="loading……">
        {code !== 0 && code !== '' ?
          <div className="m-error">
            <h2>页面出错了：{code}</h2>
            <h4>错误信息：</h4>
            {errArr.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
            <p>请联系系统管理员 </p>
          </div> :
          children
        }
      </Spin>
    )
  }
}
