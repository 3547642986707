import React, { Component, ReactNode, FocusEventHandler } from 'react'
import { Input } from 'antd'
import Svg from '../../display/svg'
import InputProps from 'antd/lib/input/index'

export interface IProps extends InputProps {
  icon?: string,
  placeholder?: string,
  autoComplete?: string,
  onChange?: Function,
  type?: string,
  id?: string,
  size?: "small" | "default" | "large",
  maxLength?: number,
  disabled?: boolean,
  value?: any,
  defaultValue?: any,
  className?: string,
  children?: ReactNode,
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>,
  onFocus?: FocusEventHandler<HTMLInputElement>,
  onBlur?: FocusEventHandler<HTMLInputElement>,
  addonAfter?: ReactNode,
  prefixCls?: string;
}

export default class ReInput extends Component<IProps> {

  change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const value = e.target.value
    onChange && onChange(value)
  }

  render() {
    const { icon, ...args } = this.props
    const prefix = icon ? (typeof icon === 'function' ? icon : <Svg src={icon}/>) : null
    return <Input  {...args} onChange={this.change} prefix={prefix}/>
  }
}
