import React, { Component } from 'react'
import Full from '../layout/full';
import { inject, observer } from 'mobx-react'
import UI, { IUI } from "../store/ui";
import Content from '../components/display/content'
import './index.less'
import Link from '../components/display/link'
import Svg from '../components/display/svg'

@inject('UI') @observer
class App extends Component<{ UI?: IUI }> {
  componentDidMount() {
    const { UI } = this.props
    UI && UI.setPageTitle('首页')
  }

  render() {
    const { UI: { initDataLoading, menuData, myMenu } = UI } = this.props
    return (
      <Full className='l-index'>
        <Content code={menuData.errno} msg={menuData.errmsg} loading={initDataLoading}>
          <div id="p-index">
            {!initDataLoading ? (myMenu && myMenu.length > 0) ?
              <div className="m-menu-box">
                {myMenu.map((menu: any) => (
                    <div className="u-menu-item" key={menu.path}>
                      <Link className="link" href={menu.path}>{menu.name}</Link>
                    </div>
                  )
                )}
              </div>
              :
              <div className="u-not-data">
                <Svg src="notData"/>
                <p className="text">你暂时没有任何系统的权限，请联系管理员</p>
              </div>
              : null
            }
          </div>
        </Content>
      </Full>
    );
  }
}

export default App;
