import Axios from 'axios'
import Config from '../config/config'
import Store from 'store'
import { notification } from 'antd'

export interface IResult {
  errno: number | string,
  errmsg: string,
  data: any,
  status?: number,
  headers?: { [key: string]: any }
}

type TMethod = 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch'

interface IMethod {
  (url: string, data?: Object | string, tips?: boolean | string, conf?: Object): Promise<IResult>
}

const axiox = Axios.create({
  timeout: 30000,
  responseType: 'json',
  headers: { 'X-Requested-With': 'XMLHttpRequest' }
})

const handleTips = (tips: string | boolean, result: IResult) => {
  if (tips) {
    if (result.errno === 0) {
      notification.success({
        message: typeof tips === 'string' ? tips : result.errmsg,
      })
    } else {
      let errmsg = ''
      if (result.errmsg && typeof result.errmsg === 'object') {
        Object.keys(result.errmsg).forEach((key: string) => errmsg += ` ${key}: ${result.errmsg[key]}`)
      } else {
        errmsg = result.errmsg
      }
      notification.error({
        message: result.errno,
        description: errmsg,
      })
    }
  }
}

const AjaxFn = async function (method: TMethod, url: string, data: Object = {}, tips: boolean | string = false, conf: { [key: string]: any } = {}): Promise<IResult> {
  // @ts-ignore
  const fn = axiox[method]
  const { hosts } = Config

  const newUrl = url.replace(/^\/([\w\d]+)\//, (a1, a2) => a2 && hosts[a2] ? `${hosts[a2]}/` : a1)
  const result: IResult = { errno: 1, errmsg: '', data: '' }
  let ajaxResult
  const token = Store.get('token')
  if (token) {
    !conf.headers && (conf.headers = {})
    conf.headers.token = token
  }
  try {
    if (['get', 'delete', 'head', 'options'].indexOf(method) >= 0) {
      ajaxResult = await fn(newUrl, { ...conf, params: data })
    } else {
      ajaxResult = await fn(newUrl, data, conf)
    }
  } catch (e) {
    if (!e.response) {
      result.errmsg = e.message
      result.errno = 600 // 网络错误
      handleTips(tips, result)
      return result
    }
    ajaxResult = e.response
  }
  const { status, headers, statusText } = ajaxResult
  result.headers = headers
  result.status = status

  const { errno = '', errmsg = '', data: rData = '' } = ajaxResult.data || {}
  result.errno = errno || status
  result.errmsg = errmsg || statusText
  result.data = rData
  if (status === 200) {
    result.errno === 200 ? result.errno = 0 : ''
    if (conf && conf.responseType === 'blob') {
      result.data = ajaxResult.data
    }
  }
  handleTips(tips, result)
  return result
}

export default axiox

export const httpGet: IMethod = async function (url: string, data: Object = {}, tips: boolean | string = false, conf: Object = {}): Promise<IResult> {
  return AjaxFn('get', url, data, tips, conf)
}
export const httpPost: IMethod = async function (url: string, data: Object = {}, tips: boolean | string = '操作成功', conf: Object = {}): Promise<IResult> {
  return AjaxFn('post', url, data, tips, conf)
}
export const httpPatch: IMethod = async function (url: string, data: Object = {}, tips: boolean | string = '操作成功', conf: Object = {}): Promise<IResult> {
  return AjaxFn('patch', url, data, tips, conf)
}
export const httpPut: IMethod = async function (url: string, data: Object = {}, tips: boolean | string = '添加成功', conf: Object = {}): Promise<IResult> {
  return AjaxFn('put', url, data, tips, conf)
}
export const httpDel: IMethod = async function (url: string, data: Object = {}, tips: boolean | string = '删除成功', conf: Object = {}): Promise<IResult> {
  return AjaxFn('delete', url, data, tips, conf)
}

export const dfData: IResult = { errno: '', errmsg: '', data: '' }
export const dfDataArr: IResult = { errno: '', errmsg: '', data: [] }
export const dfDataObj: IResult = { errno: '', errmsg: '', data: {} }
export const dfDataPage: IResult = { errno: '', errmsg: '', data: {} }
