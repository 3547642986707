import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { LocaleProvider } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import AuthPage from './page/_auth'
import Auth from './store/auth'
import UI from './store/ui'
import './app.less'

class App extends Component {
  render() {
    return (
      <Provider Auth={Auth} UI={UI}>
        <Router>
          <LocaleProvider locale={zh_CN}><AuthPage/></LocaleProvider>
        </Router>
      </Provider>
    )
  }
}

export default App
