import React from 'react'
import Loadable from 'react-loadable'
import { Spin } from 'antd'
import FORM, { IForm as IFORM } from './store/form'
import CURD, { ICURD } from './store/curd'
import LIST from './page/list'
import ADD from './page/add'
import EDIT from './page/edit'
import DETAIL from './page/detail'
import Config from '../config/config'
import oldItemMapPlus from '../components/form/itemMapPlus'
import { IResult } from '../api/method'

const Loading: any = () => <Spin spinning={true}/>

const itemMapPlus = {
  ...oldItemMapPlus,
  editor: Loadable({ loader: () => import('./editor'), loading: Loading })
}
const { successErrno, format, authErrno, validatedErrno, mobileWidth } = Config
export const Form = FORM({ format })
export const Curd = CURD({ format, successErrno })
export const List = LIST({ successErrno, format, authErrno, validatedErrno, mobileWidth, itemMap: itemMapPlus })
export const Add = ADD({ successErrno, validatedErrno, format, authErrno, mobileWidth, itemMap: itemMapPlus })
export const Edit = EDIT({ successErrno, validatedErrno, format, authErrno, mobileWidth, itemMap: itemMapPlus })
export const Detail = DETAIL({ successErrno, validatedErrno, format, authErrno, mobileWidth, itemMap: itemMapPlus })

export interface ICurd extends ICURD<IResult> {
}

export interface IForm extends IFORM {
}

